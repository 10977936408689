<template>
    <div class="broadcaster">
        <el-table :data="broadcasterTable" style="width: 100%;flex: 1"size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333',}" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="video_works" label="视频作品" align="left"  width="600">
                <template slot-scope="scope">
                    <div class="video-detail">
                        <span class="video-img">
                              <img :src="scope.row.aweme_cover" alt="">
                        </span>
                        <div class="video-right">
                            <div class="video-info">
                                <span class="video-link" :title="scope.row.aweme_title">{{ scope.row.aweme_title }}</span>
                            </div>
                            <div class="tag">
                                <span class="tag-title">标签：</span>
                                <div class="tag-list">
                                    {{scope.row.label}}
<!--                                    <span v-for="(item,index) in scope.row.hot_words" :key="index">{{item}}</span>-->
                                </div>
                            </div>
                            <div class="publish-time">
                                <span>发布时间：{{scope.row.aweme_create_time}}</span>
                            </div>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="playback" label="点赞数" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.digg_count}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="comment_count" label="评论数" align="center"></el-table-column>
            <el-table-column prop="share_count" label="转发" align="center"></el-table-column>
            <el-table-column label="操作" align="center"  width="180">
                <template slot-scope="scope">
                    <el-button type="primary" circle size="small" @click="gotoDetail(scope.row)">
                        <i class="iconfont">&#xe62d;</i>
                    </el-button>
                    <el-button type="success" circle size="small" @click="gotoData(scope.row)">
                        <i class="iconfont">&#xe61e;</i>
                    </el-button>
                    <el-button type="danger" circle size="small" @click="gotoUrl(scope.row.aweme_url)">
                       <i class="iconfont">&#xe63a;</i>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center" background style="padding-bottom: 10px;"
                :current-page="videoPages.currentPageNum"
                :page-size="videoPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="videoPages.total"
                @current-change="videoCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import dayjs from "dayjs";

    export default {
        name: "EcDataVideoModule",
        data(){
            return{
                broadcasterTable:[],
                aweme_id:'',
                author_id:'',
                //分页
                videoPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        mounted(){
            //查看详情
            this.author_id = this.$route.query.douyinuser_id;
            this.getHotPeopleVideoList()
            // this.$httpStudent.axiosGetBy(this.$api.sellGoodsPeopleDetail, param,res => {
            //     // console.log(res,1)
            //     if (res.code == 200) {
            //         this.author_id = res.data.author_id;
            //         this.getHotPeopleVideoList();
            //     }else {
            //         this.$message({
            //             type: "error",
            //             message: res.msg,
            //             duration: 1000
            //         });
            //     }
            // });
        },
        methods:{
            // 获取视频列表
            getHotPeopleVideoList(){
                let param = {
                    author_id:this.author_id,
                    page: this.videoPages.currentPageNum,
                    limit: this.videoPages.eachPageNum,
                };
                this.$httpStudent.axiosGetBy(this.$api.hotPeopleVideoList, param,res => {
                    if (res.code == 200) {
                        this.broadcasterTable = res.data.search_results.map((item) => {
                            item.aweme_create_time = dayjs(item.aweme_create_time*1000).format("YYYY/MM/DD")
                            return item
                        })
                        this.videoPages.total = res.data.page_info.totalCount;
                        this.getHotTag(res.data.data);
                    }else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                            duration: 1000
                        });
                    }
                })
            },
            // 获取热词
            getHotTag(arr){
                for (let i in arr) {
                    this.$httpStudent.axiosGetBy(this.$api.hotPeopleVideoDetails, {aweme_id:arr[i].aweme_id},res => {
                        if (res.code == 200) {
                            this.broadcasterTable[i]['hot_words'] = res.data.video_details.aweme.hot_words
                        }else {
                            this.$message({
                                type: "error",
                                message: res.msg,
                                duration: 1000
                            });
                        }
                        // console.log(res.data.video_details.aweme.hot_words,i)
                    })
                }
            },
            //分页
            videoCurrentChange(val) {
                this.videoPages.currentPageNum = val;
                this.getHotPeopleVideoList();
            },
            //进入视频详情页
            gotoDetail(row) {
                this.$router.push({
                    path: '/student/ds/video/details',
                    query:{
                        aweme_id:row.aweme_id
                    }
                })
            },
            //进入视频观众分析
            gotoData(row) {
                this.$router.push({
                    path:"/student/ds/video/details",
                    query:{
                        aweme_id: row.aweme_id,
                        activeName: 'second'
                    }
                })
            },
            // 视频播放
            gotoUrl(aweme_url){
                window.open(aweme_url)
            }
        }
    }
</script>

<style scoped lang="scss">
    .broadcaster{
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .video-detail{
        display: flex;
        align-items: center;
        .video-img{
            width: 105px;
            height: 130px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                max-width:100%;
                max-height: 100%;
            }
        }
        .video-right{
            padding-left: 16px;
            width: 1%;
            flex: 1;
            .video-info {
                line-height: 1.5;
                .video-link {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 100%;
                    display: block;
                    color: #333;
                    padding-bottom: 16px;
                    /*cursor: pointer;*/
                    /*&:hover{*/
                        /*color: #0824CE;*/
                    /*}*/
                }
            }
            .tag{
                padding-bottom: 16px;
                display: flex;
                .tag-title {
                    display: inline-block;
                    min-width: 38px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #999;
                }
                .tag-list{
                    display: inline-block;
                    max-height: 56px;
                    overflow: hidden;
                    span{
                        padding: 0 10px;
                        background: rgba(244,244,244,1);
                        border-radius: 12px;
                        display: inline-flex;
                        margin:0 10px 6px 0;
                    }
                }
            }
            .publish-time {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999;
            }
        }
    }
    ::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
        background-color: transparent;
    }
    ::v-deep.el-table__body-wrapper{
        flex: 1;
        min-height: 688px;
    }
</style>