<template>
    <div class="broadcaster-details">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="数据概况" name="first" lazy><EcDataOverviewModule /></el-tab-pane>
            <el-tab-pane label="粉丝数据分析" name="second" lazy><EcFanDataModule /></el-tab-pane>
            <el-tab-pane label="播主视频" name="third" lazy><EcDataVideoModule /></el-tab-pane>
        </el-tabs>
        <EcMineModule :mineInfo.sync="mineInfo" ref="EcMine"/>
    </div>
</template>

<script>
    import EcMineModule from '../../../components/ecmodule/EcMineModule.vue'
    import EcDataOverviewModule from '../../../components/ecmodule/EcDataOverviewModule.vue'
    import EcFanDataModule from '../../../components/ecmodule/EcFanDataModule.vue'
    import EcDataVideoModule from '../../../components/ecmodule/EcDataVideoModule.vue'
    export default {
        name: "DsSalesRankDetails",
        components: {
            EcMineModule,
            EcDataOverviewModule,
            EcFanDataModule,
            EcDataVideoModule,
        },
        data() {
            return {
                activeName: 'first',
                mineInfo: {},//用户详情

            }
        },
        created() {
            // let name = localStorage.getItem('currentTab');
            // // 判断是否存在currentTab，即tab页之前是否被点击切换到别的页面
            // if (name) {
            //     this.activeName = name
            // }
            let name = localStorage.getItem('activeName');
            if (name) {
                this.activeName = name;
                localStorage.removeItem('activeName');
            }
        },
        mounted() {
            // 获取数据概况
            let douyinuser_id = this.$route.query.douyinuser_id;
            this.getUserInfo(douyinuser_id)
        },
        destroyed(){
            localStorage.removeItem('currentTab')
        },
        methods: {
            getUserInfo(douyinuser_id) {
                // 获取用户详情
                this.$httpStudent.axiosGetBy(this.$api.sellGoodsPeopleDetail, {douyinuser_id: douyinuser_id}, res => {
                        if (res.code === 200) {
                            this.mineInfo = res.data;
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message,
                                duration: 1000
                            });
                        }
                    }
                );
            },
            handleClick(tab, event) {
                // 点击tab后触发事件，修改显示页面，将状态保存在localStorage里面
                localStorage.setItem('currentTab', tab.name)
            }
        }
    }
</script>

<style scoped lang="scss">
    .broadcaster-details {
        display: flex;
        justify-content: start;
        height: 100%;

        ::v-deep .el-tabs {
            height: 100%;
            box-shadow: none;
            border: none;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            background: transparent;
            flex: 1;
            width: 1%;
        }

        ::v-deep .el-tabs__header {
            border-bottom: none;
            margin: 0;
        }

        ::v-deep .el-tabs__content {
            flex: 1;
            background: #fff;
            padding-top: 20px;
            min-height: 766px;
        }

        ::v-deep .el-tab-pane {
            height: 100%;
        }

        ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
            background: #fff;
        }

        ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
            border-left: none;
        }

        ::v-deep .el-tabs__header .is-active {
            background: #fff;
            font-size: 16px;
            font-weight: 400;
            color: #0824ce;
            border-radius: 6px 6px 0 0;
        }

        ::v-deep .el-tabs__item {
            height: 52px;
            line-height: 52px;
        }

        ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
            border: none;
        }

        ::v-deep .el-tabs__item:hover {
            color: #0824ce;
            cursor: pointer;
        }
    }
</style>